import React from 'react';
import Projects from './Projects';
import Intro from './Intro';

class LandingPage extends React.Component {
  render() {
    return (
      <main className="landing-page">
        <div className="main">
          <header className="header">
            <Intro />
          </header>
          <div className="main__location">
            <i className="material-icons main__location--icon icon">place</i>
            <div className="main__location--text">Prague, Czech Republic</div>
          </div>
        </div>

        <section className="section section__2" id="projects">
          <div className="section__content dark-grey-text">
            <h1 className="heading heading-1 white-text">Projects</h1>
            <Projects />
          </div>
        </section>
        <section className="section section__1" id="skills">
          <div className="section__content black-text">
            <h1 className="heading heading-1" id="skills"> Technical skills</h1>
            <p className="paragraph">
              {' '}
              I am a full-stack developer proficient in HTML5, CSS3 and JavaScript in both the
              front-end (React.js) and back-end (Node.js, Java/Spring). I have experience working with both NoSQL
              and SQL databases in the form of MongoDB and PostgreSQL and am always looking to expand my
              skill set.
            </p>
            <div className="section__1--skills">
              <div className="skills">
                <h2 className="skills__heading heading-2 heading">Front-end</h2>{' '}
                <ul className="skills__list">
                  <li className="skills__list--element">JavaScript/TypeScript</li>
                  <li className="skills__list--element">React.js/Redux/ApolloGQL</li>
                  <li className="skills__list--element">Vue.js</li>
                  <li className="skills__list--element">HTML5/CSS3</li>
                </ul>
              </div>
              <div className="skills">
                <h2 className="skills__heading heading-2 heading">Back-end</h2>{' '}
                <ul className="skills__list">
                  <li className="skills__list--element">Node/Express</li>
                  <li className="skills__list--element">REST</li>
                  <li className="skills__list--element">MongoDB</li>
                  <li className="skills__list--element">PostgreSQL</li>
                  <li className="skills__list--element">GraphQL</li>
                </ul>
              </div>
              <div className="skills">
                <h2 className="skills__heading heading-2 heading">Other</h2>{' '}
                <ul className="skills__list">
                  <li className="skills__list--element">Unix/Linux</li>
                  <li className="skills__list--element">Newtworking (Cisco)</li>
                  <li className="skills__list--element">Data Stractures/Algorithms</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="section section__3" id="contact">
          <div className="section__content">
            <h2 className="section__heading heading heading-2" id="contact-me">Contact me</h2>
            <div className="section__3--email">
              Feel free to get in touch with me at{' '}
              <a href="mailto:alan.buzek@gmail.com">alan.buzek@gmail.com</a>.
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default LandingPage;
